.main-page {
  margin: 30px;
}

@media (max-width: 768px) {
  .ant-space {
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 175, 175);
}
