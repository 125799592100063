.form-add {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}

.upload-container {
  width: 128px;
  height: 128px;
  overflow: hidden;
}
