.main-domain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #192644;
  font-size: 40px;
  font-family: "Poppins";
  line-height: 70px;
  text-align: center;
  margin-top: 10px;
}

.sub-title {
  color: #525252;
  text-align: center;
  font-size: 24px;
  font-family: "Poppins";
  line-height: 25px;
  display: flex;
  width: 675px;
  height: 79px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.horizontal-bar {
  width: 117px;
  height: 3px;
  background: #41b125;
}

.domain-el {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.domain-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2%;
}

.title-domain {
  color: #283b4f;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 2%;
  margin-bottom: 3%;
}

.desc-domain {
  color: #525252;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
}

.rectangle {
  width: 110px;
  height: 110px;
  border: 4px solid #005645;
}

.first-number {
  color: #192644;
  text-align: right;
  font-size: 90px;
  font-family: "Poppins";
  font-weight: 700;
  transform: translate(37%);
}

.number {
  color: #192644;
  text-align: right;
  font-size: 90px;
  font-family: "Poppins";
  font-weight: 700;
  transform: translate(46%);
}

/* Styles pour les tablettes */
@media (max-width: 1024px) {
  .title {
    font-size: 30px;
    line-height: 35px;
  }

  .horizontal-bar {
    margin-top: 10px;
  }

  .domain-el {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .rectangle {
    width: 80px;
    height: 80px;
    border: 4px solid #005645;
  }

  .number {
    color: #192644;
    text-align: right;
    font-size: 60px;
    font-family: "Poppins";
    font-weight: 500;
    transform: translate(46%);
  }

  .first-number {
    color: #192644;
    text-align: right;
    font-size: 60px;
    font-family: "Poppins";
    font-weight: 500;
    transform: translate(31%);
  }
}

/* Styles pour les smartphones */
@media (max-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 35px;
  }

  .horizontal-bar {
    margin-top: 10px;
  }

  .domain-el {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}
