.ant-menu-title-content {
    font-size: 16px;
    font-weight: 600;
}

.ant-form-item-required {
    font-size: 14px;
    font-weight: 700;
}

.ant-form-item {
    font-size: 14px;
    font-weight: 700;
}

.btn-custom-back {
    height: 40px;
    width: 130px;
    font-size: 15px;
    font-weight: 700;
}

.ant-picker-input {
    height: 30px;
}

.custom-input-back-of {
    height: 40px;
    width: 400px;
}


@keyframes css-dev-only-do-not-override-w8mnev-uploadAnimateInlineIn {
    from {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }

    to {
        width: initial;
        height: initial;
        margin: initial;
        padding: initial;
        opacity: 1;
    }
}
