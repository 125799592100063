.form-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
}

.ant-input-number {
    min-width: 100%;
}

.form-add-project {
    width: 80%;
    height: 100px;
    margin-bottom: 50px;
}

@media (max-width: 992px) {
    .form-add {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .form-add-project {
        width: 80%;
        height: 100px;
    }
}

@media (min-width: 991px) {
    .form-add {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .form-add-project {
        width: 80%;
        height: 100px;
    }
}