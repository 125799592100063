.related-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  margin-bottom: 10%;
}

.related-image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 20px;
  max-height: 300px;
}

.row-skeleton-related-project {
  margin: auto;
  width: 7%;
}

.container-skeleton-related-project {
  flex: 1 0 calc(30% - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 5px 10px;
}

.related-image-container {
  flex: 1 0 calc(30% - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 5px 10px; 
  border-radius: 10px;
  width: 150px; 
  height: 200px;
}

.related-image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, filter 0.3s;
  border-radius: 10px;
  cursor: pointer;
}

.related-image-container img:hover {
  transform: scale(1.1);
  filter: brightness(50%);
}

.related-image-title {
  text-align: center;
  margin-top: 10px;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.related-image-container:hover .related-image-title {
  cursor: pointer;
  opacity: 1;
}

/* Responsive pour tablettes */
@media (max-width: 1024px) and (min-width: 769px) {
  .related-image-container {
    flex: 1 0 calc(45% - 10px);
    width: 200px;
    height: 250px;
  }

  .related-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150vh;
    margin-top: -45%;
    margin-bottom: 5%;
  }
}

/* Responsive pour mobiles */
@media (max-width: 768px) {
  .related-image-container {
    flex: 1 0 calc(100% - 10px);
    width: 100%;
    margin-bottom: 10px;
  }
}
