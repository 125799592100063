.main-more-detail {
  position: relative;
  border-radius: 40px;
  background: #005645;
  width: 90%;
  height: 422px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  margin-top: 2%;
}

.content-more-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cube-svg {
  position: absolute;
  top: -14%;
  right: 0%;
}

.simple-cube-svg {
  position: absolute;
  bottom: -60%;
  left: -7%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title-more-detail {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-family: "Poppins";
  line-height: 125%;
  margin-bottom: 2%;
}

.sub-title-more-detail {
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-family: "Poppins";
  line-height: 150%;
  letter-spacing: 0.22px;
  margin-bottom: 5%;
}

.btn-more-detail {
  position: sticky;
  display: inline-flex;
  padding: 18px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.36px;
  cursor: pointer;
  border-color: transparent;
}

@media (min-width: 1024px) {
  .main-more-detail {
    width: 80%;
    padding: 4%;
    height: 40%;
  }
}

/* Styles pour les tablettes */
@media (max-width: 1024px) {
  .main-more-detail {
    width: 80%;
    padding: 4%;
    height: 40%;
  }

  .title-more-detail {
    font-size: 40px;
  }

  .sub-title-more-detail {
    font-size: 20px;
  }

  .btn-more-detail {
    font-size: 14px;
    padding: 15px 10px;
  }

  .cube-svg,
  .simple-cube-svg {
    display: none;
  }
}

/* Styles pour les smartphones */
@media (max-width: 768px) {
  .main-more-detail {
    width: 80%;
    height: 40%;
    padding: 3%;
  }

  .title-more-detail {
    font-size: 22px;
  }

  .sub-title-more-detail {
    font-size: 12px;
  }

  .btn-more-detail {
    font-size: 10px;
    padding: 7px 8px;
  }
}
