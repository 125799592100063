.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 10px;
    margin-right: 10px;
    margin: 0 auto;
}

.shadow-element {
    padding: 20px;
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(178, 217, 242, 0.75);
}

.shad-el {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-login {
    display: flex;
    justify-content: center;
    align-items: center;

    ;
}