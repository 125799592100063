.main-nav {
  display: flex;
}

.nav-bar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.logo {
  margin-top: 2%;
  margin-left: 20px;
  margin-right: 10%;
  margin-bottom: 2%;
}

.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-item {
  color: #292f36;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 125%;
  margin-left: 15%;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-weight: 400;
}

.nav-item::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background-color: #39af25;
  transition: width 0.3s ease;
}

.nav-item:hover::before {
  width: 100%;
}

.nav-item.active {
  color: #005645;
  font-weight: 600;
  line-height: 150%;
  background-image: linear-gradient(#39af25, #39af25);
  background-repeat: no-repeat;
  background-size: 100% 3px;
  background-position: center bottom;
}
.active:hover::before {
  width: 0;
}

.nav-item:hover {
  color: #005645;
}

.contact-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.contact-navbar {
  position: relative;
}

.contact-navbar::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  width: 2px;
  height: 40px;
  background-color: #41b125;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  margin-right: 2%;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #292f36;
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.close-icon:hover {
  color: #005645;
}

@media (max-width: 1150px) {
  .menu-toggle {
    display: flex;
  }

  .nav-items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 25%;
    background-color: white;
    z-index: 1000;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }



  .nav-items.active {
    display: flex;
  }

  .nav-item {
    text-align: center;
    margin: 0;
    padding: 15px 0;
  }

  .contact-navbar {
    display: none;
  }

  .menu-toggle {
    position: relative;
    width: 30px;
    height: 22px;
    cursor: pointer;
  }

  .bar {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #000;
    left: 0;
    transition: all 0.3s ease; /* animation globale pour toutes les propriétés */
  }

  .bar:nth-child(1) {
    top: 0;
    transform: translateY(-50%);
  }

  .bar:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  .bar:nth-child(3) {
    top: 100%;
    transform: translateY(-50%);
  }

  .bar:nth-child(3) {
    bottom: 0;
  }

  .menu-open .bar:nth-child(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  .menu-open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-open .bar:nth-child(3) {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }

  .no-active {
    background-image: none;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
