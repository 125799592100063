.ant-menu-overflow {
    background-color: #545c64;
    height: 60px;
}

.ant-menu-item-selected {
    border-bottom: 2px solid #41B125;
    color: #41B125 !important;
    background-color: #414549 !important;
}

.ant-menu-item:hover {
    background-color: #6f7b86;
    color: #fff;
}

.ant-menu-item:not(.ant-menu-item-selected) .ant-image {
    filter: grayscale(100%);
}

.ant-menu-overflow-item {
    background: none !important;
    cursor: pointer;
}
