.global-main {
  display: flex;
  flex-direction: column;
}

.main-footer {
  display: flex;
  flex-direction: row;
  margin: 5% 5% 0 5%;
  justify-content: space-around;
  align-items: center;
  align-items: flex-end;
}

.first-child-footer {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.second-child-footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
  width: 15%;
}

.three-child-footer {
  display: flex;
  flex-direction: column;
}

.rejoindre-nos-footer {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.desc-footer {
  color: #525252;
  text-align: left;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
}

.page-footer {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #292f36;
  height: 18px;
}

.title-footer {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #292f36;
}

.footer-adresse {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: left;
  color: gray;
}

.title-page-footer {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0.01em;
  text-align: left;
  height: 5px;
}

.horizontal-bar-footer {
  width: 100%;
  height: 3px;
  background: #005645;
  margin: 20px 0 20px 0;
}

.footer-footer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-left: 5%;
  align-items: center;
  justify-content: center;
}

.title-f {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #525252;
}

.desc-f {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #a8a8a8;
  width: 40%;
  margin-left: 30%;
}

.contact-footer {
  font-family: "Poppins";
  font-size: 14px;
}

.logo-footer {
  margin-bottom: 10px;
}

@media (max-width: 1240px) {
  .footer-footer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 5%;
    align-items: center;
    justify-content: space-around;
  }

  .title-f {
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }

  .desc-f {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    width: 30%;
    margin-left: 0;
  }
}

/* Styles pour les tablettes */
@media (max-width: 1024px) {
  .main-footer {
    display: flex;
    flex-direction: column;
    margin: 5% 5% 0 5%;
  }

  .first-child-footer {
    width: 100%;
    margin-top: 10px;
  }

  .second-child-footer {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .three-child-footer {
    width: 100%;
    margin-top: 10px;
  }

  .map-container {
    height: 40vh;
  }

  .footer-footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 5%;
    align-items: center;
  }

  .title-f {
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }

  .desc-f {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    width: 80%;
    margin-left: 0;
  }
}

/* Styles pour les smartphones */
@media (max-width: 768px) {
  .main-footer {
    display: flex;
    flex-direction: column;
    margin: 5% 5% 0 5%;
  }

  .first-child-footer {
    width: 100%;
    margin-top: 30px;
  }

  .second-child-footer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .three-child-footer {
    width: 100%;
    margin-top: 30px;
  }

  .map-container {
    height: 40vh;
  }

  .footer-footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 5%;
    align-items: center;
  }

  .title-f {
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }

  .desc-f {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    width: 80%;
    margin-left: 0;
  }
}
