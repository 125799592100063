.conteneur-video {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .conteneur-video iframe {
    width: 100%;
  }
}
