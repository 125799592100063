.image-background-main {
  position: relative;
}

.image-background-main img {
  width: 100%;
  height: 349px;
}

.btn-contact {
  background-color: #ffffff;
  width: 500px;
  height: 160px;
  border-radius: 37px 37px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(80% - 90px);
  left: 50%;
  margin-left: -250px;
}

.title-btn-contact {
  font-family: "Poppins";
  font-size: 42px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
  color: #292f36;
}

.sub-title-btn-contact {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5053;
}

.title-contact-header {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 400;
  line-height: 69px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: 50px;
  color: #005645;
}

.sub-title-contact-header {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #005645;
}

.title-contact {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 400;
  line-height: 69px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: 50px;
}

.sub-title-contact {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.main-contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
  background-image: url("../../../images/BackgroundImage.svg");
  width: 97%;
}

.group-card-contact {
  display: flex;
  flex-direction: column;
}

.card-contact {
  width: 500px;
  height: 460px;
  padding: 13%;
  border-radius: 50px;
  gap: 10px;
  background-color: #f2f7f6;
  display: flex;
  flex-direction: column;
}

.item-card-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle-contact {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.text-item-contact {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.group-btn-contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
}

.btn-contact-itinerary {
  width: 239.14px;
  height: 59px;
  padding: 18px 13px 18px 13px;
  border-radius: 10px;
  gap: 10px;
  background-color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #006355;
  border-color: #006355;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.btn-contact-map {
  width: 239.14px;
  height: 59px;
  padding: 18px 13px 18px 13px;
  border-radius: 10px;
  gap: 10px;
  background-color: #006355;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffffff;
  border-color: #006355;
}

.carte-france-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .btn-contact {
    width: 50%;
    left: 25%;
    margin-left: 0;
  }

  .title-btn-contact {
    font-size: 35px;
    line-height: 31px;
  }

  .sub-title-btn-contact {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

  .title-contact {
    font-size: 24px;
    line-height: 34px;
  }

  .sub-title-contact {
    font-size: 17px;
    line-height: 26px;
  }

  .main-contact {
    flex-direction: column;
  }

  .group-card-contact {
    align-items: center;
  }

  .card-contact {
    width: 90%;
    height: auto;
    padding: 40px 20px;
  }

  .group-btn-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .btn-contact-itinerary,
  .btn-contact-map {
    width: 50%;
    height: auto;
    padding: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .btn-contact {
    width: 75%;
    left: 13%;
    margin-left: 0;
    height: 120px;
    top: calc(80% - 50px);
  }

  .text-item-contact {
    font-size: 14px;
  }

  .title-btn-contact {
    font-size: 24px;
    line-height: 31px;
  }

  .sub-title-btn-contact {
    font-size: 18px;
    line-height: 26px;
  }

  .title-contact {
    font-size: 24px;
    line-height: 34px;
  }

  .sub-title-contact {
    font-size: 17px;
    line-height: 26px;
  }

  .main-contact {
    flex-direction: column;
  }

  .group-card-contact {
    align-items: center;
  }

  .card-contact {
    width: 90%;
    height: auto;
    padding: 30px 0;
  }

  .group-btn-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .btn-contact-itinerary,
  .btn-contact-map {
    width: 80%;
    height: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .circle-contact {
    background-color: transparent;
    margin-left: 5px;
    margin-right: -10px;
  }

  .image-background-main img {
    height: 170px;
  }
}
